<template>
  <div class="traffic-page">
    <div class="traffic-container">
      <h1 class="page-title">Message Traffic</h1>
      <div class="card">
        <div class="chart-header">
          <h2 class="chart-title">
            Message Volume - {{ timePeriodDisplay }}
          </h2>
          <div class="time-period-selector">
            <button 
              @click="setTimePeriod('1h')" 
              :class="{ active: selectedTimePeriod === '1h' }"
              class="time-button"
            >1 Hour</button>
            <button 
              @click="setTimePeriod('24h')" 
              :class="{ active: selectedTimePeriod === '24h' }"
              class="time-button"
            >24 Hours</button>
            <button 
              @click="setTimePeriod('1w')" 
              :class="{ active: selectedTimePeriod === '1w' }"
              class="time-button"
            >1 Week</button>
            <button 
              @click="setTimePeriod('1m')" 
              :class="{ active: selectedTimePeriod === '1m' }"
              class="time-button"
            >1 Month</button>
          </div>
        </div>
        <div v-if="loading" class="loading-container">
          <div class="loading-spinner"></div>
          <p>Loading traffic data...</p>
        </div>
        <div v-else-if="error" class="error-message">
          <p>{{ error }}</p>
          <button @click="connectWebSocket" class="retry-button">Retry</button>
        </div>
        <div v-else-if="!hasData" class="no-data-message">
          <p>No message traffic data available for the last 24 hours.</p>
        </div>
        <div v-else class="chart-container">
          <canvas ref="trafficChart"></canvas>
        </div>
        <div class="stats-summary">
          <div class="stat-box">
            <div class="stat-value">{{ totalMessages }}</div>
            <div class="stat-label">Total Messages</div>
          </div>
          <div class="stat-box">
            <div class="stat-value">{{ avgMessagesPerHour }}</div>
            <div class="stat-label">{{ avgLabel }}</div>
          </div>
          <div class="stat-box">
            <div class="stat-value">{{ peakHour }}</div>
            <div class="stat-label">Peak</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'TrafficPage',
  data() {
    return {
      loading: true,
      error: null,
      trafficData: [],
      // chart: null, // Removed from data() to prevent reactivity issues
      socket: null,
      selectedTimePeriod: '24h', // Default to 24 hours
      isMounted: false, // Track if component is mounted
      chartInitialized: false, // Track if chart is initialized
      renderTimeout: null // For delayed rendering
    };
  },
  // Initialize chart instance property OUTSIDE data()
  chart: null, // Define as a non-reactive instance property
  computed: {
    timePeriodDisplay() {
      const displayMap = {
        '1h': 'Last Hour',
        '24h': 'Last 24 Hours',
        '1w': 'Last 7 Days',
        '1m': 'Last 30 Days'
      };
      return displayMap[this.selectedTimePeriod] || 'Last 24 Hours';
    },
    hasData() {
      return this.trafficData && this.trafficData.some(point => point.count > 0);
    },
    totalMessages() {
      if (!this.trafficData || this.trafficData.length === 0) return 0;
      return this.trafficData.reduce((total, point) => total + point.count, 0);
    },
    avgMessagesPerHour() {
      if (!this.trafficData || this.trafficData.length === 0) return 0;
      const avg = this.totalMessages / this.trafficData.length;
      return Math.round(avg * 10) / 10; // Round to 1 decimal
    },
    peakHour() {
      if (!this.trafficData || this.trafficData.length === 0) return 'N/A';
      
      // Sort data by timestamp first
      const sortedData = [...this.trafficData].sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });
      
      let maxCount = 0;
      let peakTimestamp = null;
      
      sortedData.forEach(point => {
        if (point.count > maxCount) {
          maxCount = point.count;
          peakTimestamp = point.timestamp;
        }
      });
      
      if (!peakTimestamp) return 'N/A';
      
      const date = new Date(peakTimestamp);
      
      // Format differently based on time period
      if (this.selectedTimePeriod === '1h') {
        // For 1h, show hours and minutes
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
      } else if (this.selectedTimePeriod === '24h') {
        // For 24h, show hours
        return `${date.getHours().toString().padStart(2, '0')}:00`;
      } else {
        // For 1w or 1m, show dates
        const options = { month: 'short', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
      }
    },
    avgLabel() {
      // Change the label based on the selected time period
      if (this.selectedTimePeriod === '1h') {
        return 'Avg Per 5 Min';
      } else if (this.selectedTimePeriod === '24h') {
        return 'Avg Per Hour';
      } else if (this.selectedTimePeriod === '1w') {
        return 'Avg Per Day';
      } else if (this.selectedTimePeriod === '1m') {
        return 'Avg Per Day';
      }
      return 'Average';
    }
  },
  methods: {
    handleTabChange(event) {
      // If switching away from this tab, clean up the chart
      if (event.detail && event.detail.currentTab !== 'traffic') {
        console.log('TabChange: Switching away from traffic tab. Cleaning up chart.'); // Added log
        this.cleanupChart();
      }
    },
    
    cleanupChart() {
      // Check if the chart instance exists
      if (this.chart) {
        console.log('Cleanup: Chart instance exists. Attempting cleanup.'); // Added log
        try {
          // Access the canvas associated with the Chart instance itself
          const chartCanvas = this.chart.canvas;

          if (chartCanvas && typeof chartCanvas.getContext === 'function') {
            // Check if the canvas still has a valid context potentially
            // This is a belt-and-suspenders check, getContext might fail here too in theory
            try {
               const ctx = chartCanvas.getContext('2d');
               if (ctx) {
                 console.log('Cleanup: Chart canvas and context seem valid. Destroying chart.'); // Added log
                 this.chart.destroy();
               } else {
                 console.warn('Cleanup: Chart canvas exists but failed to get context. Nullifying chart ref.'); // Added log
               }
            } catch(e) {
               console.warn('Cleanup: Error getting context during cleanup check. Nullifying chart ref.', e); // Added log
            }
          } else {
            console.warn('Cleanup: Chart instance exists, but its canvas is invalid/missing. Nullifying chart ref.'); // Added log
          }
        } catch (error) {
          // Catch errors during the destroy call itself
          console.error('Cleanup: Error occurred during chart.destroy():', error); // Added log
        } finally {
          // **Crucially, always nullify the reference** regardless of successful destroy
          this.chart = null;
          this.chartInitialized = false; // Assuming you use this flag elsewhere
          console.log('Cleanup: Chart instance reference set to null.'); // Added log
        }
      } else {
        // console.log('Cleanup: No chart instance found.'); // Optional log
      }
    },
    
    setTimePeriod(period) {
      if (this.selectedTimePeriod !== period) {
        console.log(`SetTimePeriod: Changing to ${period}. Cleaning up chart.`); // Added log
        // *** Crucially, cleanup here forces a full recreate on next data arrival ***
        this.cleanupChart();

        this.selectedTimePeriod = period;
        if (this.renderTimeout) { clearTimeout(this.renderTimeout); }
        this.loading = true;

        this.renderTimeout = setTimeout(() => {
          console.log(`SetTimePeriod: Sending request for ${period}.`); // Added log
          if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify({ type: 'set_time_period', time_period: period }));
          } else {
            console.log(`SetTimePeriod: Socket not open. Reconnecting for ${period}.`); // Added log
            this.connectWebSocket(); // This also calls cleanupChart first
          }
        }, 300);
      }
    },
    
    connectWebSocket() {
      console.log('ConnectWS: Cleaning up existing chart before connection attempt.'); // Added log
      // *** Ensure cleanup happens before loading ***
      this.cleanupChart();

      this.loading = true;
      this.error = null;
      
      const token = localStorage.getItem('token');
      if (!token) {
        this.error = 'Authentication token not found';
        this.loading = false;
        return;
      }
      
      // Close existing socket if any
      this.disconnectWebSocket();
      
      // Determine WebSocket URL (ws or wss based on protocol)
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const host = process.env.VUE_APP_API_BASE_URL.replace(/^https?:\/\//, '');
      const wsUrl = `${protocol}//${host}/ws/traffic/`;
      
      console.log('Connecting to WebSocket:', wsUrl);
      
      try {
        this.socket = new WebSocket(wsUrl);
        
        this.socket.onopen = () => {
          console.log('WebSocket connection established');
          // We'll wait for the first message to come in before clearing loading state
        };
        
        this.socket.onmessage = (event) => {
          console.log('WebSocket message received');
          try {
            const data = JSON.parse(event.data);
            if (data.type === 'traffic_data') {
              this.trafficData = data.data;
              if (data.time_period) { this.selectedTimePeriod = data.time_period; }

              // *** Schedule Render/Update ***
              this.$nextTick(() => {
                this._renderOrUpdateChart(); // Call the combined function
              });

              if (this.loading) { this.loading = false; }
            }
          } catch (err) {
            console.error('Error parsing WebSocket message:', err);
          }
        };
        
        this.socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          this.error = 'WebSocket connection error. Check console for details.';
          this.loading = false;
        };
        
        this.socket.onclose = (event) => {
          console.log('WebSocket connection closed:', event.code, event.reason);
          // Only set error if this wasn't a normal closure and we're still mounted
          if (event.code !== 1000 && this._isDestroyed !== true) {
            this.error = `WebSocket connection closed (${event.code})${event.reason ? ': ' + event.reason : ''}`;
            
            // Try to reconnect after a delay
            setTimeout(() => {
              if (this._isDestroyed !== true) { // Check if component still exists
                console.log('Attempting to reconnect WebSocket...');
                this.connectWebSocket();
              }
            }, 5000);
          }
        };
      } catch (err) {
        console.error('Error creating WebSocket connection:', err);
        this.error = `Failed to connect: ${err.message}`;
        this.loading = false;
      }
    },
    
    disconnectWebSocket() {
      if (this.socket) {
        console.log('Closing WebSocket connection');
        // Use 1000 (Normal Closure) as the code
        this.socket.close(1000, 'Component unmounted');
        this.socket = null;
      }
    },
    
    // *** Combined Render/Update Logic ***
    _renderOrUpdateChart() {
      // ... (prepare data: sortedData, newLabels, newData) ...
      if (!this.isMounted) {
        console.log('RenderUpdate: Component not mounted, skipping.');
        return;
      }

      if (!this.trafficData || this.trafficData.length === 0) {
        console.log('RenderUpdate: No traffic data. Cleaning up existing chart if any.');
        this.cleanupChart(); // Clean up if data disappears
        return;
      }

      // Prepare data AND ensure they are plain arrays
      const sortedData = [...this.trafficData].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      
      const newLabels = sortedData.map(point => { // .map already creates a new array
        const date = new Date(point.timestamp);
        if (this.selectedTimePeriod === '1h') {
          // For 1h, show hours and minutes
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          return `${hours}:${minutes}`;
        } else if (this.selectedTimePeriod === '24h') {
          // For 24h, show hours
          return `${date.getHours().toString().padStart(2, '0')}:00`;
        } else {
          // For 1w or 1m, show dates
          return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
        }
      });
      const newData = sortedData.map(point => point.count); // .map already creates a new array

      // --- Try UPDATING first ---
      // Access the non-reactive instance property 'this.chart'
      if (this.chart) {
        console.log('RenderUpdate: Existing chart found. Updating.');
        try {
          // Use the instance property
          this.chart.data.labels = newLabels;
          this.chart.data.datasets[0].data = newData;
          // Use the instance property
          this.chart.update('none');
          
          console.log('RenderUpdate: Chart updated successfully.');
        } catch (updateError) {
          console.error('RenderUpdate: Error updating chart:', updateError, '. Falling back to recreate.');
          this.cleanupChart();
          this._createChart(newLabels, newData);
        }
      }
      // --- Otherwise, CREATE ---
      else {
        console.log('RenderUpdate: No existing chart found. Creating new chart.');
        this._createChart(newLabels, newData);
      }
    },

    // *** Dedicated CREATE Logic ***
    _createChart(labels, data) {
      // ... (checks for isMounted, canvas, ctx) ...
      if (!this.isMounted) {
        console.log('CreateChart: Component not mounted, skipping creation.');
        return;
      }
      const canvas = this.$refs.trafficChart;
      if (!canvas || typeof canvas.getContext !== 'function') {
        console.error('CreateChart: Cannot find or use canvas element.');
        return; // Exit if canvas isn't ready
      }
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('CreateChart: Failed to get canvas context.');
        return; // Exit if context fails
      }

      // --- Belt-and-suspenders cleanup ---
      // Access the non-reactive instance property 'this.chart'
      if (this.chart) {
         console.warn("CreateChart: Lingering chart instance found just before creation. Attempting cleanup AGAIN.");
         this.cleanupChart();
      }
      // ---

      try {
        const datasets = [{
          label: 'Messages', data: data, /* ... other dataset options ... */
          backgroundColor: 'rgba(3, 218, 198, 0.2)', borderColor: 'rgb(3, 218, 198)', borderWidth: 2, tension: 0.3, fill: true, pointBackgroundColor: 'rgb(3, 218, 198)', pointBorderColor: '#fff', pointBorderWidth: 2, pointRadius: 4, pointHoverRadius: 6
        }];

        const options = {
          responsive: true, maintainAspectRatio: false,
          scales: { y: { beginAtZero: true, grid: { color: 'rgba(255, 255, 255, 0.1)' }, ticks: { color: 'rgba(255, 255, 255, 0.7)' } }, x: { grid: { color: 'rgba(255, 255, 255, 0.1)' }, ticks: { color: 'rgba(255, 255, 255, 0.7)' } } },
          plugins: { legend: { display: false }, tooltip: { backgroundColor: 'rgba(0, 0, 0, 0.8)', titleColor: '#fff', bodyColor: '#fff', bodyFont: { size: 14 }, padding: 10, cornerRadius: 4, displayColors: false } }
        };

        // Assign to the non-reactive instance property 'this.chart'
        this.chart = new Chart(ctx, {
          type: 'line',
          data: { labels: labels, datasets: datasets },
          options: options
        });
        
        // If using Vue 3 Composition API, you might use markRaw here:
        // this.chart = markRaw(new Chart(ctx, { ... }));

        console.log('CreateChart: New chart instance created successfully.');
        this.chartInitialized = true;
      } catch (createError) {
        console.error('CreateChart: Error during new Chart() instantiation:', createError);
        // Nullify the instance property
        this.chart = null;
        this.chartInitialized = false;
      }
    }
  },
  mounted() {
    this.isMounted = true;
    // Initialize the instance property if not done above
    this.chart = null;
    console.log('Mounted: Component mounted.');
    window.addEventListener('tabChanged', this.handleTabChange);
    this.connectWebSocket();
  },
  beforeUnmount() {
    this.isMounted = false;
    console.log('BeforeUnmount: Component unmounting.'); // Added log
    if (this.renderTimeout) { clearTimeout(this.renderTimeout); this.renderTimeout = null; }
    window.removeEventListener('tabChanged', this.handleTabChange);
    console.log('BeforeUnmount: Cleaning up chart.'); // Added log
    this.cleanupChart();
    console.log('BeforeUnmount: Disconnecting WebSocket.'); // Added log
    this.disconnectWebSocket();
  }
};
</script>

<style scoped>
.traffic-page {
  padding: 20px;
  color: var(--text-primary);
}

.traffic-container {
  max-width: 100%;
}

.page-title {
  margin-bottom: 24px;
  font-size: 28px;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 10px;
}

.card {
  background-color: var(--surface-dark);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.chart-title {
  font-size: 18px;
  color: var(--secondary-color);
  margin: 0;
}

.time-period-selector {
  display: flex;
  gap: 8px;
}

.time-button {
  padding: 6px 12px;
  background-color: var(--background-dark);
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.time-button:hover {
  background-color: rgba(3, 218, 198, 0.1);
  color: var(--text-primary);
}

.time-button.active {
  background-color: rgba(3, 218, 198, 0.2);
  color: var(--primary-color);
  border-color: var(--primary-color);
  font-weight: bold;
}

.chart-container {
  position: relative;
  height: 400px;
  margin-bottom: 24px;
}

.loading-container, .error-message, .no-data-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  text-align: center;
  color: var(--text-secondary);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(3, 218, 198, 0.2);
  border-left-color: rgb(3, 218, 198);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.error-message {
  color: var(--error-color);
}

.retry-button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

.stats-summary {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  background-color: var(--background-dark);
  border-radius: 8px;
  margin-top: 20px;
}

.stat-box {
  text-align: center;
  padding: 0 20px;
}

.stat-value {
  font-size: 28px;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 4px;
}

.stat-label {
  font-size: 14px;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chart-container {
    height: 300px;
  }
  
  .stats-summary {
    flex-direction: column;
    gap: 20px;
  }
  
  .stat-box {
    padding: 10px 0;
  }
}
</style>
