<template>
  <div class="supervisor-page">
    <supervisor-page-navigation 
      :current-tab="currentTab" 
      @update:currentTab="currentTab = $event"
    />
    <div class="content-wrapper">
      <!-- Use v-if/v-else-if pattern for all tab content -->
      <div v-if="currentTab === 'corrections'" class="supervisor-container">
        <!-- Manager List Section -->
        <div class="manager-list-section">
          <div class="manager-list-container">
            <h1>Managers</h1>
            <ul class="manager-list">
              <li v-for="username in managerUsernames" :key="username"
              :class="{ 'selected-manager': username === selectedManager }">
                <a href="#" @click.prevent="fetchManagerCorrections(username)">
                  {{ username }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        <!-- Corrections Display Section -->
        <div class="corrections-container">
          <div v-if="loadingCorrections" class="center-message">
            <p>Loading...</p>
          </div>
          <div v-else>
            <div v-if="managerCorrections && managerCorrections.length > 0">
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th class="small-column">Role</th>
                      <th>Original Content</th>
                      <th>Corrected Content</th>
                      <th class="small-column">Image URL</th>
                      <th class="small-column">Corrected</th>
                      <th>Image Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="correction in groupedCorrections[Object.keys(groupedCorrections)[currentPage]]" :key="correction.id">
                      <td class="small-column">{{ correction.role === 'user' ? 'Client' : 'Operator' }}</td>
                      <td>{{ correction.content }}</td>
                      <td :class="{
                            'high-quality': correction.was_high_quality,
                            'low-quality': correction.was_low_quality && !correction.was_high_quality
                          }">
                        {{ correction.corrected_content }}
                      </td>
                      <td class="small-column">
                        <a :href="correction.image_url" target="_blank" v-if="correction.image_url">View Image</a>
                        <span v-else>N/A</span>
                      </td>
                      <td class="small-column">{{ correction.is_corrected }}</td>
                      <td>{{ correction.image_label }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="selectedManager">
              <p>No corrections available for this manager.</p>
            </div>
            <div v-else>
              <div class="center-message">
                <p>Choose a manager from the left.</p>
              </div>
            </div>

            <div class="pagination-container">
              <div class="left-pagination-section">
                <span class="current-conversation-id">
                Current Conv. ID: {{ getCurrentConversationId() }}
                </span>
                <div class="page-navigation">
                    <input type="number" v-model="desiredPage" min="1" :max="totalPages" @keyup.enter="goToPage" class="page-input" placeholder="Go to page...">
                    <button @click="goToPage" class="go-button">Go</button>
                </div>
              </div>
              <div class="stats">
                <div class="stat-item">
                  <span class="stat-value">{{ high_quality_corrections }}</span>
                  <span class="stat-label">High Quality</span>
                </div>
                <div class="stat-item">
                  <span class="stat-value">{{ low_quality_corrections }}</span>
                  <span class="stat-label">Low Quality</span>
                </div>
                <div class="stat-item">
                  <span class="stat-value">{{ images_labeled }}</span>
                  <span class="stat-label">Images Labeled</span>
                </div>
              </div>
              <div class="pagination" ref="paginationContainer">
                <button
                  v-for="(group, index) in Object.keys(groupedCorrections)"
                  :key="index"
                  @click="setCurrentPage(index)"
                  :class="{'active-page': currentPage === index}">
                  {{ index + 1 }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <user-statistics v-else-if="currentTab === 'userStats'"></user-statistics>
      <dataset-statistics v-else-if="currentTab === 'datasetReport'"></dataset-statistics>
      <search-page v-else-if="currentTab === 'search'"></search-page>
      <ai-config-page v-else-if="currentTab === 'aiConfig'"></ai-config-page>
      <sampler-config-page v-else-if="currentTab === 'samplerConfig'"></sampler-config-page>
      <pokes-page v-else-if="currentTab === 'pokes'"></pokes-page>
      <dpo-stats-page v-else-if="currentTab === 'dpoStats'"></dpo-stats-page>
      <weekly-dpo-stats v-else-if="currentTab === 'weeklyDpoStats'"></weekly-dpo-stats>
      <traffic-page v-else-if="currentTab === 'traffic'"></traffic-page>
      
      <!-- Use v-if with a key to force component recreation when tab changes -->
      <view-recent-page 
        v-if="currentTab === 'viewRecent'" 
        :key="'viewRecent-' + Date.now()"
      ></view-recent-page>
    </div>
  </div>
</template>

<script>
import UserStatistics from './UserStatistics.vue';
import DatasetStatistics from './DatasetStatistics.vue';
import SearchPage from './SearchPage.vue';
import AiConfigPage from './AiConfigPage.vue';
import SamplerConfigPage from './SamplerConfigPage.vue';
import PokesPage from './PokesPage.vue';
import DpoStatsPage from './DpoStatsPage.vue';
import WeeklyDpoStats from './WeeklyDpoStats.vue';
import ViewRecentPage from './ViewRecentPage.vue';
import TrafficPage from './TrafficPage.vue';
import SupervisorPageNavigation from './SupervisorPageNavigation.vue';

export default {
  components: {
    UserStatistics,
    DatasetStatistics,
    SearchPage,
    AiConfigPage,   
    SamplerConfigPage,
    PokesPage,
    DpoStatsPage,
    WeeklyDpoStats,
    ViewRecentPage,
    TrafficPage,
    SupervisorPageNavigation
  },
  name: 'SupervisorPage',
  data() {
    return {
        token: null,
        managerUsernames: [],
        managerCorrections: null,
        selectedManager: null,
        currentPage: 0,
        groupedCorrections: {},
        currentTab: 'traffic', // Changed default tab to 'traffic'
        loadingCorrections: false,
        high_quality_corrections: 0,
        low_quality_corrections: 0,
        images_labeled: 0,
    };
  },
  methods: {
    getToken(){
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async fetchManagerUsernames() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_managers/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
        });
        if (response.ok) {
          this.managerUsernames = await response.json();
        } else {
          console.error('Error fetching manager usernames');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async fetchManagerCorrections(username) {
      this.selectedManager = username;
      this.loadingCorrections = true; 
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_manager_corrections/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({ username })
        });
        if (response.ok) {
          this.managerCorrections = await response.json();
          console.log('MANAGER CORRECTIONS:', this.managerCorrections);
          this.fetchManagerStats(username)
        } else {
          console.error('Error fetching manager corrections');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loadingCorrections = false;
      }
    },
    async fetchManagerStats(managerUsername) {
      try {
        const url = new URL(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_manager_stats/`);
        if (managerUsername) {
          url.searchParams.append('manager_username', managerUsername);
        }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
        });
        if (response.ok) {
          const stats = await response.json();
          console.log('Manager stats:', stats);
          this.high_quality_corrections = stats.high_quality_corrections;
          this.low_quality_corrections = stats.low_quality_corrections;
          this.images_labeled = stats.images_labeled;
        } else {
          console.error('Error fetching manager stats');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    groupCorrectionsByConversationId() {
      const grouped = {};
      if(this.managerCorrections) {
        this.managerCorrections.forEach(correction => {
        if (!grouped[correction.conversation]) {
          grouped[correction.conversation] = [];
        }
        grouped[correction.conversation].push(correction);
      });
      }
      this.groupedCorrections = grouped;
      console.log("GROUPED CONVERSATIONS:", this.groupedCorrections);
      this.currentPage = 0;
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    getCurrentConversationId() {
      const conversationIds = Object.keys(this.groupedCorrections);
      return conversationIds[this.currentPage] || 'N/A';
    },
    goToPage() {
        const page = parseInt(this.desiredPage, 10) - 1;
        if (!isNaN(page) && page >= 0 && page < this.totalPages) {
            this.setCurrentPage(page);
            this.scrollToPage(page);
        } else {
            alert('Invalid page number');
        }
    },
    scrollToPage(pageIndex) {
        const buttonWidth = 55;
        const scrollPosition = pageIndex * buttonWidth;
        this.$refs.paginationContainer.scrollLeft = scrollPosition;
    },
    loadCurrentTab() {
      const savedTab = localStorage.getItem('currentTab');
      if (savedTab) {
        this.currentTab = savedTab;
      }
    },
    saveCurrentTab() {
      localStorage.setItem('currentTab', this.currentTab);
    },
  },
  computed: {
      totalPages() {
          return Object.keys(this.groupedCorrections).length;
      },
      isManagerListVisible() {
        return this.currentTab === 'corrections';
      },
  },
  watch: {
    managerCorrections: {
        immediate: true,
        handler() {
          this.groupCorrectionsByConversationId();
        },
      },
      currentTab() {
        this.saveCurrentTab();
        
        // Emit a custom event when the tab changes
        window.dispatchEvent(new CustomEvent('tabChanged', {
          detail: { currentTab: this.currentTab }
        }));
      },
    },
  mounted() {
    this.getToken();
    this.fetchManagerUsernames();
    this.loadCurrentTab();
  }
};
</script>

<style scoped>
/* Content wrapper */
.content-wrapper {
  padding-top: 0; /* No padding needed since tabs are not fixed */
}

/* Main layout */
.supervisor-container {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 120px); /* Adjusted since tabs are not fixed */
  background-color: var(--background-dark);
  color: var(--text-primary);
  position: relative;
}

/* Manager list styling - only show when needed */
.manager-list-section {
  border-right: 1px solid var(--border-color);
  background-color: var(--surface-dark);
  width: 220px;
  transition: all 0.3s ease;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 5;
  display: flex;
}

.manager-list-container {
  position: relative; /* Changed from sticky to relative */
  overflow-y: auto;
  max-height: calc(100vh - 120px); /* Adjusted since tabs are not fixed */
  padding: 20px;
  width: 100%;
}

.manager-list-container h1 {
  margin: 0 0 20px 0;
  font-size: 1.6rem;
  color: var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  padding-bottom: 10px;
  text-align: center;
  letter-spacing: 0.5px;
}

.manager-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.manager-list li {
  margin-bottom: 10px;
  border-radius: 6px;
  transition: all 0.2s ease;
  overflow: hidden;
}

.manager-list li:hover {
  background-color: var(--hover-color);
  transform: translateX(3px);
}

.manager-list li a {
  color: var(--text-primary);
  text-decoration: none;
  display: block;
  padding: 12px 15px;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 1.05rem;
}

.selected-manager {
  background-color: var(--primary-dark) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.selected-manager a {
  color: white !important;
  font-weight: bold;
}

/* Corrections container styling */
.corrections-container {
  flex-grow: 1;
  height: calc(100vh - 120px); /* Adjusted since tabs are not fixed */
  overflow-y: auto;
  padding: 0 20px;
}

.table-container {
  max-height: calc(100vh - 180px); /* Adjusted since tabs are not fixed */
  overflow-y: auto;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  background-color: var(--surface-dark);
}

/* Table styling - applied to all tables */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  background-color: var(--surface-dark);
  margin-top: 0;
  border-radius: 10px;
  overflow: hidden;
}

th, td {
  border: 1px solid var(--border-color);
  padding: 14px;
  text-align: left;
  color: var(--text-primary);
}

th {
  background-color: var(--primary-dark);
  color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 0.9rem;
}

tr:nth-child(even) {
  background-color: #2a2a2a; /* Darker grey */
}

tr:nth-child(odd) {
  background-color: #3a3a3a; /* Lighter grey */
}

tr:hover {
  background-color: var(--hover-color);
}

td {
  min-width: 120px;
  word-break: break-word;
  line-height: 1.5;
}

/* Quality indicators */
.high-quality {
  background-color: rgba(3, 218, 198, 0.15) !important;
  border-left: 4px solid var(--success-color) !important;
  position: relative;
}

.high-quality::after {
  content: "High Quality";
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.7rem;
  background-color: var(--success-color);
  color: black;
  padding: 2px 6px;
  border-radius: 4px;
  opacity: 0.8;
}

.low-quality {
  background-color: rgba(255, 183, 77, 0.15) !important;
  border-left: 4px solid var(--warning-color) !important;
  position: relative;
}

.low-quality::after {
  content: "Low Quality";
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.7rem;
  background-color: var(--warning-color);
  color: black;
  padding: 2px 6px;
  border-radius: 4px;
  opacity: 0.8;
}

/* Center message styling */
.center-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;
  width: 100%;
}

.center-message p {
  font-size: 1.6rem;
  margin: 0;
  color: var(--text-secondary);
  background-color: var(--card-dark);
  padding: 25px 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-left: 5px solid var(--primary-color);
}

/* Pagination styling */
.pagination-container {
  position: relative; /* Changed from fixed to relative */
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--surface-dark);
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: 65px;
  padding: 0 20px;
  box-sizing: border-box;
  border-top: 1px solid var(--border-color);
  margin-top: 20px; /* Added margin to separate from content */
}

.left-pagination-section {
  display: flex;
  align-items: center;
}

.current-conversation-id {
  margin-right: 25px;
  min-width: 200px;
  color: var(--text-secondary);
  font-size: 0.95rem;
  background-color: var(--card-dark);
  padding: 8px 12px;
  border-radius: 4px;
  border-left: 3px solid var(--primary-color);
}

.page-navigation {
  display: flex;
  align-items: center;
}

.page-input {
  padding: 10px 14px;
  margin-right: 10px;
  width: 80px;
  background-color: var(--card-dark);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  border-radius: 6px;
  font-size: 0.95rem;
}

.page-input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
}

.go-button {
  padding: 10px 18px;
  background-color: var(--secondary-color);
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  transition: all 0.2s ease;
  letter-spacing: 0.5px;
}

.go-button:hover {
  background-color: var(--secondary-light);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pagination {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 5px 0;
  max-width: 30%;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--surface-dark);
}

.pagination::-webkit-scrollbar {
  height: 6px;
}

.pagination::-webkit-scrollbar-track {
  background: var(--surface-dark);
  border-radius: 3px;
}

.pagination::-webkit-scrollbar-thumb {
  background-color: var(--primary-dark);
  border-radius: 3px;
}

.pagination button {
  flex: 0 0 auto;
  padding: 10px 14px;
  margin-right: 6px;
  background-color: var(--card-dark);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 6px;
  min-width: 40px;
  transition: all 0.2s ease;
  font-weight: 500;
}

.pagination button:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pagination .active-page {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
  box-shadow: 0 4px 8px rgba(98, 0, 234, 0.3);
}

/* Stats styling */
.stats {
  display: flex;
  align-items: center;
  background-color: rgba(3, 218, 198, 0.1);
  padding: 0 15px;
  border-radius: 6px;
  margin: 0 15px;
  border: 1px solid var(--border-color);
  height: 45px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  padding: 0 10px;
  border-right: 1px solid var(--border-color);
}

.stat-item:first-child {
  margin-left: 0;
}

.stat-item:last-child {
  border-right: none;
  margin-right: 0;
}

.stat-value {
  font-weight: bold;
  font-size: 1.1rem;
  color: var(--secondary-color);
  line-height: 1.2;
}

.stat-label {
  font-size: 0.7rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1;
}

/* Utility classes */
.small-column {
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Loading animation */
@keyframes pulse {
  0% { opacity: 0.6; transform: scale(0.98); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0.6; transform: scale(0.98); }
}

.loading-animation {
  animation: pulse 1.5s infinite ease-in-out;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .tabs button {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
  
  .stats {
    padding: 8px 15px;
  }
  
  .stat-item {
    margin-left: 15px;
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .tabs-container {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  
  .content-wrapper {
    padding-top: 0;
  }
}
</style>
