<template>
  <div class="tabs-container">
    <div class="tabs">
      <button
        @click="$emit('update:currentTab', 'corrections')"
        :class="{ 'active-tab': currentTab === 'corrections' }"
      >
        Corrections
      </button>
      <button
        @click="$emit('update:currentTab', 'userStats')"
        :class="{ 'active-tab': currentTab === 'userStats' }"
      >
        User Statistics
      </button>
      <button
        @click="$emit('update:currentTab', 'datasetReport')"
        :class="{ 'active-tab': currentTab === 'datasetReport' }"
      >
        Dataset Report
      </button>
      <button
        @click="$emit('update:currentTab', 'search')"
        :class="{ 'active-tab': currentTab === 'search' }"
      >
        Search
      </button>
      <button
        @click="$emit('update:currentTab', 'aiConfig')"
        :class="{ 'active-tab': currentTab === 'aiConfig' }"
      >
        AI Config
      </button>
      <button
        @click="$emit('update:currentTab', 'samplerConfig')"
        :class="{ 'active-tab': currentTab === 'samplerConfig' }"
      >
        Sampler Config
      </button>
      <button
        @click="$emit('update:currentTab', 'pokes')"
        :class="{ 'active-tab': currentTab === 'pokes' }"
      >
        Pokes
      </button>
      <button
        @click="$emit('update:currentTab', 'dpoStats')"
        :class="{ 'active-tab': currentTab === 'dpoStats' }"
      >
        DPO Stats
      </button>
      <button
        @click="$emit('update:currentTab', 'weeklyDpoStats')"
        :class="{ 'active-tab': currentTab === 'weeklyDpoStats' }"
      >
        Weekly Stats
      </button>
      <button
        @click="$emit('update:currentTab', 'traffic')"
        :class="{ 'active-tab': currentTab === 'traffic' }"
      >
        Traffic
      </button>
      <button
        @click="handleViewRecentTab"
        :class="{ 'active-tab': currentTab === 'viewRecent' }"
      >
        View Recent
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupervisorPageNavigation',
  props: {
    currentTab: {
      type: String,
      required: true
    }
  },
  methods: {
    handleViewRecentTab() {
      // Close any existing WebSocket connections
      const existingWebSockets = document.querySelectorAll('*');
      existingWebSockets.forEach(element => {
        if (element._vue && element._vue.socket && typeof element._vue.disconnectWebSocket === 'function') {
          console.log('Closing existing WebSocket connection before switching to ViewRecent tab');
          element._vue.disconnectWebSocket();
        }
      });
      
      // Emit the tab change event
      this.$emit('update:currentTab', 'viewRecent');
    }
  }
};
</script>

<style scoped>
/* Tabs styling */
.tabs-container {
  display: block; /* Changed from flex to block to ensure normal document flow */
  padding: 0;
  background-color: var(--background-dark);
  border-bottom: 1px solid var(--border-color);
  position: static; /* Changed from fixed to static to ensure it's in the normal document flow */
  height: auto; /* Changed from fixed height to auto */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: auto; /* Changed from 100 to auto */
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--background-dark);
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--background-dark);
  position: static; /* Ensure it's in the normal document flow */
}

.tabs::-webkit-scrollbar {
  height: 3px;
}

.tabs::-webkit-scrollbar-track {
  background: var(--background-dark);
}

.tabs::-webkit-scrollbar-thumb {
  background-color: var(--primary-dark);
  border-radius: 3px;
}

.tabs button {
  padding: 16px 20px;
  border: none;
  background-color: transparent;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 0;
  transition: all 0.2s ease;
  font-weight: 500;
  letter-spacing: 0.5px;
  position: relative;
  margin: 0;
  border-bottom: 3px solid transparent;
}

.tabs button:hover {
  background-color: rgba(98, 0, 234, 0.1);
  color: var(--text-primary);
}

.tabs button.active-tab {
  background-color: transparent;
  color: var(--primary-color);
  font-weight: 600;
  border-bottom: 3px solid var(--primary-color);
}

.tabs button.active-tab::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 3px 3px 0 0;
}

/* Responsive adjustments for tabs */
@media (max-width: 1200px) {
  .tabs button {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .tabs-container {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
}
</style>
